<template>
  <div>
    <section
      v-if="success.status === ''"
      id="contactForm"
    >
      <b-card
        v-if="/(date|forms)/.test($t(form.concerne, '-k~l'))"
        no-body
        class="bg-light mb-4"
      >
        <b-card-body
          v-html="$t(`home.reminder.${$t(form.concerne, '-k~l')}`)"
        />
      </b-card>

      <h2 v-html="$t('home.form.title')"></h2>
      <b-card bg-variant="light">
        <b-form @submit="sendMail">
          <div>
            <!-- Name -->
            <b-form-group
              id="f-cf-name-wrapper"
              label-for="f-cf-name"
              label-sr-only
              :label="`${$t('home.form.name')} (${$t('home.form.required')})`"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <i class="fas fa-user fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>
                <b-form-input
                  id="f-cf-name"
                  v-model="form['your-name']"
                  :placeholder="$t('home.form.name')"
                  type="text"
                  required
                  trim
                  :state="ok.name"
                  @focusout="check('name')"
                />
              </b-input-group>
            </b-form-group>

            <!-- Email -->
            <b-form-group
              id="f-cf-email-wrapper"
              label-for="f-cf-email"
              label-sr-only
              :label="`${$t('home.form.email')} (${$t('home.form.required')})`"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <i class="far fa-envelope fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>
                <b-form-input
                  id="f-cf-email"
                  v-model="form['your-email']"
                  :placeholder="$t('home.form.email')"
                  type="email"
                  required
                  trim
                  :state="ok.email"
                  @focusout="check('email')"
                />
              </b-input-group>
              <p
                class="alert"
                v-html="$t('home.alert.email')"
              ></p>
            </b-form-group>
          </div>
          <div>
            <!-- Subject -->
            <b-form-group
              id="f-cf-subject-wrapper"
              label-for="f-cf-subject"
              label-sr-only
              :label="`${$t('home.form.subject')} (${$t('home.form.required')})`"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <i class="fas fa-question fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>
                <b-form-input
                  id="f-cf-subject"
                  v-model="form['your-subject']"
                  :placeholder="$t('home.form.subject')"
                  type="text"
                  required
                  trim
                  :state="ok.subject"
                  @focusout="check('subject')"
                />
              </b-input-group>
            </b-form-group>

            <!-- Concern -->
            <b-form-group
              id="f-cf-concerne-wrapper"
              label-for="f-cf-concerne"
              label-sr-only
              :label="`${$t('home.form.concerne')} (${$t('home.form.required')})`"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <i class="fas fa-crosshairs fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>

                <!--
                  Select locked for
                  - framastuff (help)
                  - support
                  - event
                -->
                <b-form-select
                  v-if="section === 'help' && rt.includes($t(form.concerne, '-k~lL@'))
                    || /(participate|support|event)/.test(section)"
                  id="f-cf-concerne"
                  v-model="form.concerne"
                  disabled
                  required
                  @focusout="check('concerne')"
                >
                  <option
                    :value="form.concerne"
                    v-text="form.concerne"
                  ></option>
                </b-form-select>

                <!--
                  Select for
                  - partnership
                  - framablog
                  - framabook
                -->
                <b-form-select
                  v-if="section === 'partnership'"
                  id="f-cf-concerne"
                  v-model="form.concerne"
                  required
                  @focusout="check('concerne')"
                >
                  <option
                    value=""
                    v-text="'---'"
                  ></option>
                  <option
                    :value="wpcf[2]"
                    v-text="$t('home.concerne[2]')"
                  ></option>

                  <option
                    :value="$t('txt.blog')"
                    v-text="$t('txt.blog')"
                  ></option>

                  <option
                    :value="$t('txt.book')"
                    v-text="$t('txt.book')"
                  ></option>
                </b-form-select>

                <!--
                  Select for general
                -->
                <b-form-select
                  v-if="section === 'general'"
                  id="f-cf-concerne"
                  v-model="form.concerne"
                  required
                  @focusout="check('concerne')"
                >
                  <option
                    value=""
                    v-text="'---'"
                  ></option>
                  <option
                    v-for="(label, index) in wpcf"
                    :key="index"
                    :value="label"
                    v-text="$t(`home.concerne[${index}]`)"
                  ></option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- Message -->
            <b-form-group
              id="f-cf-message-wrapper"
              label-for="f-cf-message"
              label-sr-only
              :label="`${$t('home.form.message')} (${$t('home.form.required')})`"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <i class="fas fa-pen fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>
                <b-form-textarea
                  id="f-cf-message"
                  v-model="form['your-message']"
                  :placeholder="$t('home.form.message')"
                  cols="40"
                  rows="10"
                  required
                  @focusin="bienveillance = true"
                  @focusout="check('message')"
                />
                <p
                  v-show="form.concerne === 'Newsletter' && ok.email"
                  class="alert alert-warning col-12 mx-0"
                >
                  <span v-html="$t(`home.alert.newsletter`)"></span>
                  <!-- <Unsubscribe
                    :email="form['your-email']"
                    variant="light border btn-sm"
                  /> -->
                </p>
                <p
                  v-show="false"
                  class="alert alert-info col-12 mx-0"
                  v-html="$t('home.alert.kindness')
                    .replace('<a href', '<a target=\'_blank\' href')"
                ></p>
                <p
                  v-show="noEmergency()"
                  class="alert alert-dark col-12 mx-0 text-center"
                >
                  <img
                    alt=""
                    class="img-fluid"
                    :src="`${$t('baseurl')}img/fire.webp`"
                  />
                </p>
                <div
                  v-show="noInclusive()"
                  class="alert alert-warning col-12 mx-0"
                >
                  <p
                    v-html="$t('home.alert.inclusive')
                      .replace('<a href', '<a target=\'_blank\' href')"
                  ></p>
                  <a href="https://framablog.org/2018/08/13/ecriture-du-blog-nous-ne-transigerons-pas-sur-les-libertes/">
                    <b-card
                      class="col-md-8 mx-auto mb-2 p-0"
                      img-alt=""
                      img-src="https://framablog.org/wp-content/uploads/2018/08/shruggie-00.jpg"
                      img-top
                    >
                      <b-card-text>
                        <b class="h5">
                          Écriture du blog : nous ne transigerons pas sur les libertés
                        </b>
                        <br />
                        <span class="fc-g6">framablog.org</span>
                      </b-card-text>
                    </b-card>
                  </a>
                </div>
                <div>
                  <p
                    v-for="(alert, id) in $t('home.alert')"
                    v-show="form.concerne === $t(`txt.${id}`)"
                    :key="id"
                    class="alert alert-warning col-12 mx-0"
                    v-html="$t(`home.alert.${id}`)
                      .replace('<a href', '<a target=\'_blank\' href')"
                  ></p>
                  <p
                    class="alert alert-danger col-12 mx-0"
                    v-html="$t('home.alert.mdp')
                      .replace('<a href', '<a target=\'_blank\' href')"
                  ></p>
                </div>
              </b-input-group>
            </b-form-group>

            <!-- File -->
            <b-form-group
              id="f-cf-file-wrapper"
              label-for="f-cf-file"
              label-sr-only
              :label="`${$t('home.form.file')} (${$t('home.form.optional')})`"
            >
              <b-input-group
                :title="`${$t('home.form.fileHelp')} ${ext.join(', ')}`"
              >
                <b-input-group-prepend>
                  <i class="fas fa-paperclip fa-lg fa-fw fc-g6"></i>
                </b-input-group-prepend>
                <b-form-file
                  id="f-cf-file"
                  v-model="form['your-file']"
                  plain
                  :accept="ext.join()"
                />
              </b-input-group>
            </b-form-group>
          </div>

          <!-- Submit -->
          <b-button
            block
            :disabled="noUnsubscribe()"
            type="submit"
            :variant="`lg btn-${bs}`"
          >
            <span
              v-if="!submit"
              v-html="$t('home.form.submit')"
            ></span>
            <span v-else>
              <b-spinner
                v-for="n in 3"
                :key="n"
                type="grow"
                variant="light spinner-grow-sm"
                :label="n === 1 ? $t('home.form.sending') : ''"
                class="mx-1"
              />
            </span>
          </b-button>
        </b-form>
      </b-card>
    </section>

    <section
      v-if="success.status === 'mail_sent'"
      class="confirm"
    >
      <p class="text-center">
        <i class="fas fa-check fa-2x fa-shape-circle fa-border fd-v5 fc-v5"></i>
      </p>
      <p
        class="alert"
        v-html="$t('home.alert.sent')"
      ></p>
    </section>

    <section
      v-if="/failed$/.test(success.status)"
      class="confirm"
    >
      <p class="text-center">
        <i class="fas fa-xmark fa-2x fa-shape-circle fa-border fd-r5 fc-r5"></i>
      </p>
      <p
        class="alert"
        v-html="$t('home.alert.failed')"
      ></p>
    </section>
  </div>
</template>

<style lang="scss">
#contactForm {
  .text-muted {
    display:none;
  }

  .alert, .form-control, .btn {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }

  .input-group-prepend {
    border-right: 0;
    border-left: 0;
    background: #fff;
    padding: 0.375rem;
    text-align: center;
  }

  input, textarea, select {
    border-right: 0;
    border-left: 0;
    box-shadow: none;
    opacity: 1;
  }

  .alert {
    margin: 0;
    border: 0;
    font-size: 13px;
    font-weight: normal;

    a {
      background: linear-gradient(to top, #ccc 1px, transparent 1px);
      color: inherit;

      &:hover,
      &:focus {
        background: linear-gradient(to top, #bbb 1px, #ffffff77 1px);
        color: inherit;
        text-decoration: none;
      }
    }
  }

  textarea.form-control {
    border: 0;
    border-left: 1px dashed #ccc;
  }

  #f-cf-name-wrapper,
  #f-cf-email-wrapper {
    margin-bottom: 0;

    .input-group-prepend, input {
      border-top: 0;
    }

    .alert {
      padding-top: 5px;
    }
  }

  #f-cf-subject-wrapper,
  #f-cf-concerne-wrapper {
    .input-group-prepend, input, select {
      border-top: 0;
      border-radius: 0;
      border-right: 0;
      border-left: 0;
    }
  }

  #f-cf-message-wrapper {
    margin-bottom: 0;
    background: #fff;

    .input-group-prepend {
      vertical-align: top;
      padding-top: 10px;
      border: 0;
    }

    .alert {
      margin: 5px 5px 0;
      padding: 5px 10px;
    }
  }

  #f-cf-subject-wrapper,
  #f-cf-concerne-wrapper {
    margin-bottom: 0;
  }

  #f-cf-file-wrapper {
    background: #fff;
    padding: 0;

    .input-group-prepend,
    input[type="file"] {
      border-bottom: 0;
      height: auto;
      line-height: 30px;
      padding-top: 5px;
      width: auto;
      padding-left: 5px;
    }

    label {
      display: none;
    }
  }

  #f-cf-concerne-wrapper,
  #f-cf-file-wrapper {
    .input-group-prepend {
      cursor: help;
    }
  }
}
</style>

<script>
// import Unsubscribe from '../../components/Unsubscribe.vue';

export default {
  components: {
    // Unsubscribe,
  },

  props: {
    concern: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      rt: this.$t('cols')[0].concat(this.$t('cols')[1]).concat(this.$t('cols')[2]),
      wpcf: [ // labels in Wordpress Contact Form
        'Annonce d\'un événement libre',
        'Invitation à un événement',
        'Partenariat',
        'Contact presse',
        'Newsletter',
        'Questions sur l\'association',
        'Prestation / Emploi : réponse ou candidature spontanée',
      ],
      bienveillance: false,
      ext: [
        '.gif', '.png', '.jpg', '.jpeg', '.svg', '.pdf', '.txt', '.doc',
        '.docx', '.pps', '.ppt', '.pptx', '.odt', '.odg', '.odp', '.log',
        '.rtf', '.tex', '.csv', '.xls', '.xslx', '.vcf', '.xml', '.gpx',
        '.css', '.gz',
      ],
      form: {
        _wpcf7: '423',
        _wpcf7_version: '5.1.1',
        _wpcf7_locale: 'en_US',
        _wpcf7_unit_tag: 'wpcf7-f423-p5-o1',
        _wpcf7_container_post: '5',
        'g-recaptcha-response': '',
        'mailou-158': '',
        'your-name': '',
        'your-email': '',
        concerne: '', // with an 'e' for WP Contact Form
        'your-subject': '',
        'your-message': '',
        'your-file': null,
        'your-referrer': `${document.referrer} ; ${document.location.href}`,
        'your-languages': `${window.navigator.languages} Viewport : ${window.innerWidth}x${window.innerHeight}`,
      },
      ok: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
      success: {
        into: '',
        message: '',
        status: '',
      },
      submit: false,
      bs: 'primary',
    };
  },

  watch: {
    concern(newValue) {
      if (newValue) {
        this.form.concerne = this.concern;
        this.bs = this.section === 'help'
          ? 'warning'
          : this.concern
            .replace('Soutenir', 'warning')
            .replace('Invitation à un événement', 'warning')
            .replace(/^(Partenariat|Framablog|Framabook)$/, 'primary')
            .replace('Participer', 'primary')
            .replace(/^(Newsletter|---)$/, 'primary');
      }
    },
  },

  methods: {
    check(input) {
      const el = document.getElementById(`f-cf-${input}`);
      switch (input) {
        case 'email':
          if (el.validity.valueMissing) {
            this.ok.email = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].home.form.error_empty);
          } else if (el.validity.typeMismatch) {
            this.ok.email = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].home.form.error_email);
          } else {
            this.ok.email = true;
            el.setCustomValidity('');
          }
          break;
        default:
          if (el.validity.valueMissing) {
            this.ok[input] = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].home.form.error_empty);
          } else {
            this.ok[input] = true;
            el.setCustomValidity('');
          }
          break;
      }
    },

    sendMail(e) {
      const time = Math.round(
        (new Date().getTime() - this.$parent.$parent.$parent.activity[0]) / 1000,
      );
      const ids = this.$parent.$parent.$parent.activity.slice(1).join();
      if (!this.submit) {
        this.submit = true;
        this.form['your-referrer'] += ` ; ${time}s,${ids}`;
        const sendData = new FormData();
        Object.entries(this.form).map(([key, val]) => sendData.append(key, val));

        fetch('https://contact.framasoft.org/wp-json/contact-form-7/v1/contact-forms/423/feedback', {
          method: 'POST',
          body: sendData,
        }).then(res => res.json())
          .then((data) => { this.success = data; })
          .catch(err => console.error(err)); // eslint-disable-line
      }
      e.preventDefault();
    },

    noUnsubscribe() {
      return false;
      /* this.form.concerne === 'Newsletter'
        && this.ok.email
        && /(desabonne|desinscri|unsubscribe|annul|suppr|pas-recevoir)/
          .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@'));
      */
    },

    noEmergency() {
      return /(urgent|emergency|urgence)/
        .test(this.$t(`${this.form['your-subject']}`, '-klL@'));
    },

    noInclusive() {
      return /points?-median/
        .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@'))
        || (
          /(ecriture|francais|langu)/
            .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@'))
          && /(inclus|exclu)/
            .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@')));
    },
  },
};
</script>
