<template>
  <section :id="section">
    <h2 v-html="$t(`home.${section}.title`)"></h2>
    <p v-if="referrer.match">
      <span v-html="$t('home.alert.referrer')"></span>
      <b-button
        variant="outline-light border text-secondary btn-sm"
        :href="`#${this.$t(this.$t(`txt.${referrer.key}`), '-klL@')}`"
        onclick="return false"
        @click="goToFAQ($t(`txt.${referrer.key}`))"
      >
        <i :class="`${$t(`icon.${referrer.key}`)} fa-lg fa-fw`"></i>
        <span v-html="$t(`color.${referrer.key}`)"></span>
      </b-button>
    </p>

    <b-row class="mt-4">
      <b-col
        v-for="(col, index) in this.$t('cols')"
        :key="index"
        md="4"
      >
        <b-list-group>
          <b-list-group-item
            v-for="id in col"
            :key="id"
            button
            :class="`${$t(`txt.${id}`, '-L@')}${
              $t(concern, '-~klL@') === $t(`txt.${id}`, '-~Ll@')
                ? ' fb_g2'
                : ''}`"
            :title="$te(`desc.${id}`) ? $t(`desc.${id}`) : ''"
            @click="goToFAQ($t(`txt.${id}`))"
          >
            <i
              v-show="$t(concern, '-~klL@') === $t(`txt.${id}`, '-~Ll@')"
              class="fas fa-check float-right"
            ></i>
            <i :class="`${$t(`icon.${id}`)} fa-lg fa-fw fc-g7`"></i>
            <span v-html="$t(`color.${id}`)"></span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  props: {
    section: {
      type: String,
      required: true,
    },

    concern: {
      type: String,
      required: true,
    },

    referrer: {
      type: Object,
      default() {
        return {
          href: '',
          host: '',
          key: '',
          match: false,
        };
      },
    },
  },

  methods: {
    getFramaname(key) {
      return this.$te(`color.${this.$t(key, '-k~lL@')}`)
        ? this.$t(`color.${this.$t(key, '-k~lL@')}`)
        : undefined;
    },

    goToFAQ(target) {
      const newState = {};
      if (this.section === 'help') {
        Object.assign(newState, {
          section: this.section,
          concern: target,
          active: 3,
          disabled: [4],
          hash: this.$t(target, '-klL@'),
        });
      } else {
        Object.assign(newState, {
          section: this.section,
          concern: `Participer - ${this.$t(target, '-k~lL@')}`,
          active: 3,
          disabled: [4],
          hash: `contrib-${this.$t(target, '-klL@')}`,
        });
      }
      this.$parent.$parent.$parent.state = newState;
      window.location.hash = newState.hash;
      document.getElementById('sections').scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
