<template>
  <div>
    <section>
      <b-container class="ombre">
        <h1 v-html="$t('newsletter.title')"></h1>
        <b-row align-h="center">
          <b-col
            lg="8"
            xl="9"
          >
            <div v-html="$t('newsletter.intro')"></div>

            <div class="clearfix mb-2">
              <b-form
                method="post"
                name="subscribeform"
                action="https://listmonk.framasoft.org/subscription/form"
                class="col-md-10 bg-light mx-auto my-4 px-4 py-3 rounded border"
              >
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <!-- Only decorative-->
                    <b-button
                      aria-hidden="true"
                      class="disabled"
                    >
                      <i class="far fa-envelope fc-g1 fa-fw"></i>
                    </b-button>
                    <label
                      class="sr-only"
                      for="nl-email"
                      v-html="$t('newsletter.your-email')"
                    ></label>
                  </b-input-group-prepend>
                  <b-form-input
                    id="nl-email"
                    v-model="email"
                    name="email"
                    type="email"
                    size="40"
                    :title="$t('newsletter.type-your-email')"
                    :placeholder="$t('newsletter.your-email')"
                  />
                </b-input-group>
                <div class="text-right">
                  <!-- <Unsubscribe :email="email" /> -->
                  <b-button
                    variant="primary"
                    name="subscribe"
                    type="submit"
                    value="subscribe"
                  >
                    <i class="fas fa-bell fc-f1"></i>
                    <span v-html="$t('newsletter.subscribe')"></span>
                  </b-button>
                </div>
                <div style="display: none">
                  <input
                    id="c9c93"
                    checked
                    name="l"
                    type="checkbox"
                    value="c9c93baf-1117-44f4-9e32-a37ed4a97f58"
                  />
                </div>
              </b-form>
            </div>
            <p v-html="$t('newsletter.nb')"></p>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section id="archives">
      <b-container class="ombre">
        <b-row>
          <b-col>
            <h2 v-html="$t('newsletter.archives')"></h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="year in years"
            :key="year"
            lg="6"
            class="mb-4"
          >
            <h3 v-html="year"></h3>
            <b-card
              no-body
              mig-top
            >
              <img
                alt=""
                class="card-img"
                :src="`${$t('baseurl')}img/nl/${year}.jpg`"
              />
              <b-card-body>
                <b-card-text>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(i, k) in nl[year].nl"
                      :key="i"
                      :href="`https://framablog.org/?p=${nl[year].nl[k].split(';')[1]}`"
                    >
                      <span
                        v-html="`N°${nl[year].nl[k].split(';')[0]} - ${
                          (new Date(`20${k}`))
                            .toLocaleDateString($t('lang'), {
                              month: 'long'
                            })}`"
                      ></span>
                    </b-list-group-item>
                  </b-list-group>
                  <div
                    v-if="nl[year].hs"
                    class="mt-2"
                  >
                    <b-list-group>
                      <b-list-group-item
                        v-for="(i, k) in nl[year].hs"
                        :key="i"
                        :href="`https://framablog.org/?p=${nl[year].hs[k].split(';')[1]}`"
                      >
                        <span
                          v-html="`${$t('newsletter.hs')
                          } n°${nl[year].hs[k].split(';')[0].replace('hs', '')} - ${
                            (new Date(`20${k}`))
                              .toLocaleDateString($t('lang'), {
                                month: 'long'
                              })}`"
                        ></span>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    const meta = this.$t('lang') === 'fr'
      ? {
        link: [
          {
            rel: 'alternate',
            type: 'application/rss+xml',
            title: this.$t('newsletter.title'),
            href: 'https://framablog.org/category/newsletter/feed/',
          },
        ],
      }
      : {};
    return meta;
  },

  data() {
    const nl = {
      2024: {
        nl: {
          '24-11-27': '38;36517'
        },
        hs: {
          '24-06-18': 'hs07;35237'
        }
      },
      2023: {
        nl: {
          '23-12-12': '37;33185',
          '23-11-14': '36;32685',
          '23-08-22': '35;31852',
          '23-04-18': '34;30907',
        },
        hs: {
          '23-05-24': 'hs06;31227',
          '23-01-24': 'hs05;30403',
        },
      },
      2022: {
        nl: {
          '22-12-13': '33;30261',
          '22-10-19': '32;30338',
          '22-06-23': '31;30369',
          '22-03-23': '30;30387',
        },
        hs: {
          '22-06-22': 'hs04;30411',
          '22-01-18': 'hs03;30413',
        },
      },
      2021: {
        nl: {
          '21-12-08': '29;30481',
          '21-11-04': '28;30492',
          '21-07-13': '27;30495',
        },
      },
      2020: {
        nl: {
          '20-12-16': '26;30498',
          '20-09-04': '25;30506',
          '20-05-06': '24;30512',
        },
      },
      2019: {
        nl: {
          '19-12-09': '23;30512',
          '19-10-16': '22;30514',
        },
      },
      2018: {
        nl: {
          '18-12-01': '21;30518',
          '18-10-29': '20;30521',
          '18-07-02': '19;30524',
          '18-05-05': '18;30526',
        },
        hs: {
          '18-01-25': 'hs02;30559',
        },
      },
      2017: {
        nl: {
          '17-12-13': '17;30528',
          '17-10-14': '16;30530',
          '17-06-01': '15;30532',
          '17-03-24': '14;30534',
        },
        hs: {
          '17-09-28': 'hs01;30557',
        },
      },
      2016: {
        nl: {
          '16-12-16': '13;30537',
          '16-10-14': '12;30539',
          '16-06-18': '11;30541',
          '16-04-06': '10;30543',
        },
      },
      2015: {
        nl: {
          '15-12-10': '09;30545',
          '15-10-28': '08;30547',
          '15-06-23': '07;30549',
          '15-03-25': '06;30551',
        },
      },
      2014: {
        nl: {
          '14-10-22': '05;30553',
          '14-06-28': '04;30555',
        },
      },
    };

    return {
      email: '',
      nl,
      years: Object.keys(nl).reverse(),
    };
  },

  mounted() {
    const q = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (q.nl !== undefined) {
      Object.keys(this.$t('newsletter.posts')).forEach((k) => {
        if (this.$t(`newsletter.posts.${k}.id`) === q.nl) {
          this.active = k;
        }
      });
    } else {
      [this.active] = [Object.keys(this.$t('newsletter.posts'))
        .filter(k => !/^hs/.test(this.$t(`newsletter.posts.${k}.id`)))
        .reverse()[0]];
    }
  },
};
</script>

<style lang="scss">
#newsletter .card-img {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#newsletter #nl {
  padding-top: 1rem;

  h2::after {
    content: none;
  }

  a:not(.btn-soutenir) {
    background: none;
  }
}

#newsletter .mt-sm-n4.pr-sm-0 {
  display: none;
}
</style>
