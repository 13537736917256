<template>
  <b-container class="ombre">
    <h1 v-html="$t('faq.title')"></h1>
    <div v-html="$t('faq.intro')"></div>
    <b-row>
      <!-- Search & TOC -->
      <b-col lg="4">
        <b-list-group
          class="position-sicky sticky-top"
          style="top: 20px; max-height: calc(100vh - 40px); overflow-y: scroll; z-index: 998;"
        >
          <Search
            :search="search"
            :faq="faq"
          />
          <TOC />
        </b-list-group>
      </b-col>

      <!-- Answers -->
      <b-col
        lg="8"
        order-lg="1"
      >
        <section id="subfaq">
          <Answers
            :search="search"
            :faq="JSON.stringify(faq)"
          />
        </section>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Search from './faq/Search.vue';
import TOC from './faq/TOC.vue';
import Answers from './faq/Answers.vue';

export default {
  components: {
    Search,
    TOC,
    Answers,
  },

  data() {
    return {
      search: '',
      faq: {},
      lang: this.$i18n.locale,
    };
  },

  created() {
    this.loadFaq();

    setInterval(() => {
      if (this.$i18n.locale !== this.lang) {
        this.faq = {};
        this.loadFaq();
        this.lang = this.$i18n.locale;
      }
    }, 500);

    /* Edition mode */
    const q = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (q.edit !== undefined) {
      document.body.classList.add('wiki-edit-enabled');
    } else {
      document.body.classList.remove('wiki-edit-enabled');
    }
  },

  methods: {
    loadFaq() {
      Object.keys(this.$t('faq.ranking')).sort().forEach((key) => {
        this.faq[key] = { variant: '' };
        const q = this.$t(`faq.ranking.${key}`);

        for (let i = 0; i < q.length; i += 1) {
          this.faq[key][q[i]] = {
            q: this.$t(`faq.${key}.${q[i]}.q`),
            a: this.$t(`faq.${key}.${q[i]}.a`),
            variant: '',
          };
        }
      });
    },
  },
};
</script>

<style lang="scss">
#faq {
  .ombre h2::after {
    content: initial;
  }

  #subfaq {
    .list-group-item.active {
      background: #FFF8E3;
    }

    .list-group-item:not(.active),
    p.text-right {
      display: block;
    }

    .filter {
      h2,
      .list-group-item:not(.search),
      .btn-wiki-edit {
        display: none;
      }

      .search h2 {
        display: block;
      }
    }
  }

  .btn-wiki-edit {
    visibility: hidden;
  }

  &.wiki-edit-enabled {
    .btn-wiki-edit {
      visibility: visible;
      z-index: 2;
      position: absolute;
      right: 1rem;
      background: #fff;

      &:hover {
        background: var(--f-secondary);
      }
    }

    .list-group-item.active .btn-wiki-edit {
      display: none;
    }
  }
}
</style>
