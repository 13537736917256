<template>
  <div>
    <b-list-group-item
      v-for="key in categories"
      :key="key"
      :href="`#${getFramaname(key) !== undefined
        ? $t(getFramaname(key), '-tlL').replace(/[^a-z]/g, '')
        : key}`"
      class="d-none d-lg-block"
    >
      <i :class="`${getSectionIcon(key)} fc-g6`"></i>
      <span v-html="getFramaname(key) ? getFramaname(key) : $t(`faq.${key}.title`)"></span>
    </b-list-group-item>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: Object.keys(this.$t('faq.ranking')).sort(),
    };
  },

  methods: {
    getFramaname(key) {
      return this.$te(`color.${this.$t(key, '-k~lL@')}`)
        ? this.$t(`color.${this.$t(key, '-k~lL@')}`)
        : undefined;
    },

    getSectionIcon(key) {
      let icon = 'fas fa-circle-question';
      if (this.$te(`icon.${this.$t(key, '-k~L@')}`)) {
        icon = this.$t(`icon.${this.$t(key, '-k~L@')}`);
      }
      if (this.$te(`faq.icon.${key}`)) {
        icon = this.$t(`faq.icon.${key}`);
      }
      return icon;
    },
  },
};
</script>
