<template>
  <div class="home">
    <section class="illustration">
      <b-container>
        <b-row>
          <b-col class="d-flex align-items-end justify-content-center">
            <h1
              class="sr-only"
              v-html="$t('home.title')"
            ></h1>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <b-container :class="`ombre p-0 ${state.section}`">
      <!--
        An anchor is needed because
        sections are invisible for scrollto()
      -->
      <a
        id="sections"
        ref="sections"
        aria-hidden="true"
      ></a>
      <b-card no-body>
        <b-tabs
          pills
          card
          justified
          active-nav-item-class="font-weight-bold bg-transparent"
        >
          <b-tab
            no-body
            :active="state.active === 1"
            @click="goTo(); section = ''"
          >
            <template v-slot:title>
              <b-badge
                pill
                v-text="1"
              />
              <span
                class="d-block d-lg-inline text-nowrap"
                v-html="$t('home.breadcrumb[0]')"
              ></span>
            </template>
            <b-card-body>
              <b-row
                align-h="center"
                align-v="center"
              >
                <b-col
                  xl="8"
                  lg="9"
                  v-html="$t('home.intro')"
                />
              </b-row>
            </b-card-body>
            <b-card-footer class="row m-0 px-3">
              <b-list-group class="col-md-6 p-2">
                <b-list-group-item
                  :href="referrer.help ? '' : '#help'"
                  onclick="return false"
                  @click="!referrer.help ? goTo('help') : null"
                >
                  <i
                    :class="`${$t('icon.aide')
                    } fa-lg fa-fw fc-o6 fa-shape-circle fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.help[0]')"
                  ></span>
                  <span
                    v-if="!referrer.help"
                    v-html="$t('home.menu.help[1]')"
                  ></span>
                  <span v-else>
                    <span v-html="$t('home.menu.help[2]')"></span>
                    <br />
                    <b-button
                      variant="outline-light border text-secondary btn-sm mt-2"
                      :href="`#${this.$t(this.$t(`txt.${referrer.key}`), '-klL@')}`"
                      onclick="return false"
                      @click="goTo('help', referrer.key)"
                    >
                      <i :class="`${$t(`icon.${referrer.key}`)} fa-lg fa-fw`"></i>
                      <span v-html="$t(`color.${referrer.key}`)"></span>
                    </b-button>
                    <b-button
                      variant="outline-light border text-secondary btn-sm mt-2"
                      href="#help"
                      onclick="return false"
                      @click="goTo('help')"
                      v-html="$t('home.menu.help[3]')"
                    />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  :href="referrer.help ? '' : '#participate'"
                  onclick="return false"
                  @click="!referrer.help ? goTo('participate') : null"
                >
                  <i
                    :class="`fas fa-paw fa-lg fa-fw fc-f7 fa-shape-circle
                    fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.participate[0]')"
                  ></span>
                  <span
                    v-if="!referrer.help"
                    v-html="$t('home.menu.participate[1]')"
                  ></span>
                  <span v-else>
                    <span v-html="$t('home.menu.participate[2]')"></span>
                    <br />
                    <b-button
                      variant="outline-light border text-secondary btn-sm mt-2"
                      href="#participate"
                      onclick="return false"
                      @click="goTo('participate', referrer.key)"
                    >
                      <i :class="`${$t(`icon.${referrer.key}`)} fa-lg fa-fw`"></i>
                      <span v-html="$t(`color.${referrer.key}`)"></span>
                    </b-button>
                    <b-button
                      href="#participate"
                      onclick="return false"
                      variant="outline-light border text-secondary btn-sm mt-2"
                      @click="goTo('participate')"
                      v-html="$t('home.menu.participate[3]')"
                    />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  href="#support"
                  onclick="return false"
                  @click="goTo('support')"
                >
                  <i
                    :class="`fas fa-heart fa-lg fa-fw fc-o6 fa-shape-circle
                    fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.support[0]')"
                  ></span>
                  <span
                    v-html="$t('home.menu.support[1]')"
                  ></span>
                </b-list-group-item>
              </b-list-group>
              <b-list-group class="col-md-6 p-2">
                <b-list-group-item
                  :href="referrer.partnership ? '' : '#partnership'"
                  onclick="return false"
                  @click="!referrer.partnership ? goTo('partnership') : null"
                >
                  <i
                    :class="`far fa-handshake fa-lg fa-fw fc-f7 fa-shape-circle
                    fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.partnership[0]')"
                  ></span>
                  <span
                    v-if="!referrer.partnership"
                    v-html="$t('home.menu.partnership[1]')"
                  ></span>
                  <span v-else>
                    <span v-html="$t('home.menu.partnership[2]')"></span>
                    <br />
                    <b-button
                      variant="outline-light border text-secondary btn-sm mt-2"
                      :href="`#${$t(`txt.${referrer.key}`, '-l')}`"
                      onclick="return false"
                      @click="goTo('partnership', referrer.key)"
                    >
                      <i :class="`${$t(`icon.${referrer.key}`)} fa-lg fa-fw`"></i>
                      <span v-html="$t(`color.${referrer.key}`)"></span>
                    </b-button>
                    <b-button
                      href="#partnership"
                      onclick="return false"
                      variant="outline-light border text-secondary btn-sm mt-2"
                      @click="goTo('partnership')"
                      v-html="$t('home.menu.partnership[3]')"
                    />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  href="#event"
                  onclick="return false"
                  @click="goTo('event')"
                >
                  <i
                    :class="`fas fa-microphone fa-lg fa-fw fc-o6 fa-shape-circle
                    fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.event[0]')"
                  ></span>
                  <span
                    v-html="$t('home.menu.event[1]')"
                  ></span>
                </b-list-group-item>
                <b-list-group-item
                  href="#general"
                  onclick="return false"
                  @click="goTo('general')"
                >
                  <i
                    :class="`fas fa-envelope fa-lg fa-fw fc-f7 fa-shape-circle
                    fa-white float-left mr-2 mt-1`"
                  ></i>
                  <span
                    class="h4"
                    v-html="$t('home.menu.general[0]')"
                  ></span>
                  <span
                    v-html="$t('home.menu.general[1]')"
                  ></span>
                </b-list-group-item>
              </b-list-group>
            </b-card-footer>
          </b-tab>

          <b-tab
            :active="state.active === 2"
            :title-link-class="state.disabled.includes(2) ? 'disabled' : ''"
            @click="goTo(`${state.section === 'participate' ? 'participate' : 'help'}`)"
          >
            <template v-slot:title>
              <b-badge
                pill
                v-text="2"
              />
              <span
                class="d-block d-lg-inline text-nowrap"
                v-html="$t('home.breadcrumb[1]')"
              ></span>
            </template>
            <b-card-body>
              <a id="help"></a>
              <a id="participate"></a>
              <ServiceChoice
                :section="state.section"
                :concern="state.concern"
                :referrer="referrer"
              />
            </b-card-body>
          </b-tab>

          <b-tab
            :active="state.active === 3"
            :disabled="state.disabled.includes(3)"
          >
            <template v-slot:title>
              <b-badge
                pill
                v-text="3"
              />
              <span
                class="d-block d-lg-inline text-nowrap"
                v-html="$t('home.breadcrumb[2]')"
              ></span>
            </template>
            <b-card-body>
              <b-row
                align-h="center"
                align-v="center"
              >
                <b-col
                  xl="8"
                  lg="9"
                >
                  <a id="support"></a>
                  <SubFAQ
                    :section="state.section"
                    :concern="state.concern"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-tab>

          <b-tab
            :active="state.active === 4"
            :disabled="state.disabled.includes(4)"
          >
            <template v-slot:title>
              <b-badge
                pill
                v-text="4"
              />
              <span
                class="d-block d-lg-inline text-nowrap"
                v-html="$t('home.breadcrumb[3]')"
              ></span>
            </template>
            <b-card-body>
              <b-row
                align-h="center"
                align-v="center"
              >
                <b-col
                  xl="8"
                  lg="9"
                >
                  <a id="partnership"></a>
                  <a id="event"></a>
                  <a id="general"></a>
                  <ContactForm
                    :concern="state.concern"
                    :section="state.section"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import ServiceChoice from './home/ServiceChoice.vue';
import SubFAQ from './home/SubFAQ.vue';
import ContactForm from './home/ContactForm.vue';

export default {
  components: {
    ServiceChoice,
    SubFAQ,
    ContactForm,
  },

  data() {
    const referrer = {
      href: document.referrer,
    };

    [referrer.host] = [referrer.href.split('/')[2] || ''];
    referrer.key = this.$t(referrer.host
      .replace(/^(www|test)\./i, '')
      .replace(/\.(com|net|org|fr|pro)$/i, ''),
    '-k~');
    referrer.help = referrer.host !== ''
        && this.$t('cols').join().split(',').includes(referrer.key)
        && referrer.host === this.$t(`link.${referrer.key}`).split('/')[2];
    referrer.partnership = referrer.host !== ''
        && ['blog', 'book'].includes(referrer.key)
        && referrer.host === this.$t(`link.${referrer.key}`).split('/')[2];

    return {
      referrer,
      activity: [],
      state: {
        section: '',
        concern: '',
        active: 1,
        disabled: [2, 3, 4],
        hash: '',
      },
      servicesList: this.$t('cols').join().split(','),
    };
  },

  mounted() {
    this.subroute();
    window.onhashchange = () => {
      if (window.location.hash.replace(/^#/, '') !== this.state.hash) {
        this.state.hash = window.location.hash.replace(/^#/, '').replace(/$^/, 'home');
        this.subroute();
      }
    };
  },

  methods: {
    subroute() {
      const hash = this.state.hash !== ''
        ? this.state.hash
        : window.location.hash.replace(/^#/, '');

      if (/^(home)/.test(hash)) {
        this.goTo('home');
      }

      if (/^(general)/.test(hash)) {
        this.goTo('general');
      }

      if (/^(soutenir|dons|soutien|support)/.test(hash)) {
        this.goTo('support');
      }

      if (/^(participer|participate)/.test(hash)) {
        this.goTo('participate');
      }

      if (/^(partenariat|partnership)/.test(hash)) {
        this.goTo('partnership');
      }

      if (/^(help|aide)/.test(hash)) {
        this.goTo('help');
      }

      if (/^(press)/.test(hash)) {
        this.goTo('press');
      }

      if (/^(newsletter)/.test(hash)) {
        this.goTo('news');
      }

      if (/^(issue-git)/.test(hash)) {
        this.goTo('issue-git');
      }

      // Framatrucs
      if (this.servicesList.includes(this.$t(hash, '-k~L@'))) {
        this.goTo('help', this.$t(`txt.${this.$t(hash, '-k~lL@')}`));
      }

      if (['blog', 'book'].includes(this.$t(hash, '-k~L@'))) {
        this.goTo('partnership', this.$t(`txt.${this.$t(hash, '-k~lL@')}`));
      }

      if (/^(contrib-)/.test(hash)
        && this.servicesList.includes(this.$t(hash.split('-')[1], '-k~L@'))) {
        this.goTo('participate', this.$t(`txt.${this.$t(hash.split('-')[1], '-k~lL@')}`));
      }
    },

    goTo(target, service) {
      // service => Framasphère
      const key = this.$t(service, '-k~lL@') || ''; // => sphere
      const fullkey = this.$t(`txt.${key}`, '-lL@'); // => framasphere

      switch (target) {
        case 'event':
          Object.assign(this.state, {
            section: 'event',
            concern: 'Invitation à un événement',
            disabled: [2],
            active: 4,
            hash: target,
          });
          break;

        case 'general':
          Object.assign(this.state, {
            section: 'general',
            concern: '---',
            disabled: [2, 4],
            active: 3,
            hash: target,
          });
          break;

        case 'issue-git':
          Object.assign(this.state, {
            section: 'participate',
            concern: 'Participer',
            disabled: [],
            active: 4,
            hash: target,
          });
          break;

        case 'help':
          if (!this.servicesList.includes(key)) {
            Object.assign(this.state, {
              section: 'help',
              concern: '',
              disabled: [3, 4],
              active: 2,
              hash: target,
            });
          } else {
            Object.assign(this.state, {
              section: 'help',
              concern: this.$t(`txt.${key}`),
              disabled: [4],
              active: 3,
              hash: fullkey,
            });
          }
          break;

        case 'news':
          Object.assign(this.state, {
            section: 'general',
            concern: 'Newsletter',
            disabled: [2],
            active: 4,
            hash: 'newsletter',
          });
          break;

        case 'participate':
          if (!this.servicesList.includes(key)) {
            Object.assign(this.state, {
              section: 'participate',
              concern: 'Participer',
              disabled: [3, 4],
              active: 2,
              hash: 'participate',
            });
          } else {
            Object.assign(this.state, {
              section: 'participate',
              concern: `Participer - ${this.$t(`txt.${key}`)}`,
              disabled: [4],
              active: 3,
              hash: `contrib-${fullkey}`,
            });
          }
          break;

        case 'partnership':
          if (['blog', 'book'].includes(key)) {
            Object.assign(this.state, {
              section: 'partnership',
              concern: this.$t(`txt.${key}`),
              disabled: [2, 4],
              active: 3,
              hash: target,
            });
          } else {
            Object.assign(this.state, {
              section: 'partnership',
              concern: 'Partenariat',
              disabled: [2, 4],
              active: 3,
              hash: target,
            });
          }
          break;

        case 'press':
          Object.assign(this.state, {
            section: 'general',
            concern: 'Contact presse',
            disabled: [2],
            active: 4,
            hash: target,
          });
          break;

        case 'support':
          Object.assign(this.state, {
            section: 'support',
            concern: 'Soutenir',
            disabled: [2, 4],
            active: 3,
            hash: target,
          });
          break;

        default:
          Object.assign(this.state, {
            section: '',
            concern: '',
            disabled: [2, 3, 4],
            active: 1,
            hash: '',
          });
          break;
      }
      window.location.hash = this.state.hash;
      this.$refs.sections.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
#home {
  .illustration {
    background: url('../../public/img/opengraph/home.jpg') top center;
    background-size: cover;
    min-height: 30rem;

    h1 {
      display: none;
      margin-top: 20rem;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0.25rem 1rem;
      border-radius: 5px;
    }
  }

  .container.ombre {
    margin-top: -5rem;
  }

  .alert-status {
    margin: 30px auto;

    li {
      padding: 10px;
    }
  }

  .card,
  .card-header,
  .card-footer {
    border: 0;
  }

  .card-footer {
    background: var(--f-g1);
  }

  .card-header {
    padding: 0;
  }

  .card-header-pills {
    margin: 0;
  }

  .nav-pills {
    .nav-link {
      border-radius: 0;
      padding: 1.25rem;
      font-weight: bold;
      color: var(--f-d9);

      .badge {
        opacity: 0.7;
        margin-right: 0.5rem;
        background: var(--f-violet);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-violet) 5px, transparent 0);
        color: inherit;

        .badge { opacity: 1; }
      }

      &:not(.active):not(.disabled):hover {
        background: var(--f-g2);
      }

      &.disabled {
        color: var(--f-g7);
        font-weight: normal;
        filter: grayscale(100%);
      }
    }

    li + li.nav-item::before {
      content: "⟩";
      font-weight: bold;
      color: var(--f-g4);
      margin-top: 1.25rem;
      float: left;
    }

    // allow click on service choice => go to help
    li:nth-of-type(2) .nav-link.disabled {
      pointer-events: initial;
    }
  }

  h2::after {
    content: " ";
    border-bottom: 5px solid var(--f-violet);
    display: block;
    max-width: 80px;
    margin: 0.5rem 0 1.25rem;
  }

  .help {
    .nav-link {
      .badge {
        background: var(--f-orange);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-orange) 5px, transparent 0);
      }
    }

    h2::after {
      border-bottom-color: var(--f-orange);
    }
  }

  .participate {
    .nav-link {
      .badge {
        background: var(--f-violet);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-violet) 5px, transparent 0);
      }
    }

    h2::after {
      border-bottom-color: var(--f-violet);
    }
  }

  .support {
    .nav-link {
      .badge {
        background: var(--f-o6);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-o6) 5px, transparent 0);
      }
    }

    h2::after {
      border-bottom-color: var(--f-o6);
    }
  }

  .event {
    .nav-link {
      .badge {
        background: var(--f-orange);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-orange) 5px, transparent 0);
      }
    }

    h2::after {
      border-bottom-color: var(--f-orange);
    }
  }

  .partnership {
    .nav-link {
      .badge {
        background: var(--f-violet);
      }

      &.active {
        background: linear-gradient(0deg,var(--f-violet) 5px, transparent 0);
      }
    }

    h2::after {
      border-bottom-color: var(--f-violet);
    }
  }

  /* Bootstrap "soutenir" */
  .text-soutenir {
    color: var(--f-orange);
  }

  .de-google-ify-internet {
    display:none;
  }
}
</style>
