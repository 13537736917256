<template>
  <b-navbar
    class="align-items-center justify-content-center justify-content-md-end px-0"
  >
    <b-navbar-nav>
      <b-nav-item
        :href="$t('link.docs')"
        @click.prevent.stop="$root.$children[0].modal.docs = true"
      >
        <i :class="`${$t('icon.doc')} fa-lg fa-fw fc-g6 d-lg-none`"></i>
        <span
          class="sr-only"
          v-html="$t('menu.documentation')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu.documentation')"
        ></span>
      </b-nav-item>

      <b-nav-item :href="$t('link.colibri')">
        <i :class="`${$t('icon.colibri')} fa-lg fa-fw fc-g6 d-lg-none`"></i>
        <span
          class="sr-only"
          v-html="$t('menu.forum')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu.forum')"
        ></span>
      </b-nav-item>

      <b-nav-item
        routeur-tag="li"
        active-class="active"
        :to="`/${$t('lang')}/newsletter`"
      >
        <i :class="`${$t('icon.newsletter')} fa-lg fa-fw fc-g6 d-lg-none`"></i>
        <span
          class="sr-only"
          v-html="$t('newsletter.title')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('newsletter.title')"
        ></span>
      </b-nav-item>

      <b-nav-item
        routeur-tag="li"
        active-class="active"
        :to="`/${$t('lang')}/faq`"
      >
        <i :class="`${$t('icon.faq')} fa-lg fa-fw fc-g6 d-lg-none`"></i>
        <span
          class="sr-only"
          v-html="$t('menu.faq')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu.faq')"
        ></span>
      </b-nav-item>

      <b-nav-item>
        <I18n />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>
