<template>
  <section id="subfaq">
    <div v-if="/^Participer/.test(concern)">
      <h2
        v-html="`${$t('subfaq.participate.title')} ${
          $t(`color.${concern.split(' - ')[1]}`)}`"
      ></h2>
      <div
        v-html="$t('home.participate.dev', html)"
      ></div>

      <b-row align-h="center">
        <b-col
          v-if="$te(`src.${concern.split(' - ')[1]}`)"
          sm="6"
        >
          <a
            :href="$t(`src.${concern.split(' - ')[1]}`)"
            class="btn btn-block border mb-4"
          >
            <i
              :class="`fas fa-code-branch fa-lg fc-f7 fa-shape-circle
              fa-white fa-block-center`"
            ></i>
            <span v-html="$t(`soft.${concern.split(' - ')[1]}`)"></span>
          </a>
        </b-col>
        <b-col
          v-if="$te(`git.${concern.split(' - ')[1]}`)"
          sm="6"
        >
          <a
            :href="$t(`git.${concern.split(' - ')[1]}`)"
            class="btn btn-block border mb-4"
          >
            <i
              :class="`fab fa-git fa-lg fc-f7 fa-shape-circle
              fa-white fa-block-center`"
            ></i>
            <span v-html="$t(`color.${concern.split(' - ')[1]}`)"></span>
          </a>
        </b-col>
      </b-row>
      <div v-html="$t('home.participate.other')"></div>
      <div v-html="$t('subfaq.participate.next')"></div>
      <b-row>
        <b-col
          sm="6"
          class="mx-auto p-0"
        >
          <a
            :href="`${$t('link.colibri')}/c/qualite/23/`"
            class="btn btn-block border mt-2 mb-4"
          >
            <i
              :class="`fas fa-comment fa-lg fc-${bs} fa-shape-circle
              fa-white fa-block-center`"
            ></i>
            <span v-html="$t('subfaq.participate.forum')"></span>
          </a>
        </b-col>
      </b-row>
    </div>

    <!-- Reminders ~date/~forms -->
    <b-card
      v-if="/^(date|forms)$/.test($t(concern, '-k~l'))"
      no-body
      class="bg-light mb-4"
    >
      <b-card-body>
        <div v-html="$t(`home.reminder.${$t(concern, '-k~l')}`)"></div>
      </b-card-body>
    </b-card>

    <!-- Answers -->
    <div
      :class="`${
        /^all$/.test(state.toggle) ? 'all' : ''
      } ${
        search !== '' ? 'filter' : 'nofilter'
      }`"
    >
      <h2
        v-if="categories.includes(category)"
        v-html="`${
          getFramaname(category) === undefined
            ? $t(`faq.${category}.title`)
            : getFramaname(category)
        } - ${$t('home.faq')}`"
      ></h2>
      <h2
        v-else
        v-html="$t('subfaq.mainFaq')"
      ></h2>

      <b-input-group
        size="lg"
        class="mb-4"
      >
        <b-input-group-append>
          <b-button
            variant=" fc-g7"
            @click="search = ''"
          >
            <i
              v-show="search === ''"
              class="fas fa-magnifying-glass"
            ></i>
            <i
              v-show="search !== ''"
              class="fas fa-delete-left"
            ></i>
          </b-button>
        </b-input-group-append>
        <b-form-input
          v-model="search"
          :aria-label="$t('txt.search')"
          :placeholder="$t('txt.search')"
          type="text"
          size="40"
          class="rounded-0"
        />
      </b-input-group>

      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in faq"
          :key="index"
          :active="state.toggle === item.id"
          :variant="item.variant"
        >
          <a
            v-show="state.toggle === item.id"
            href="#!"
            class="float-right close"
            aria-hidden="true"
            onclick="return false"
            @click="toggleAnswer(item.id)"
          >×</a>
          <a
            :id="item.id"
            :href="`${$t('link.contact')}/faq/#${item.id}`"
            class="toggle"
            onclick="return false"
            @click="toggleAnswer(item.id)"
          ></a>
          <h3
            class="list-group-item-heading"
            @click="toggleAnswer(item.id)"
            v-html="item.question"
          ></h3>
          <div
            class="list-group-item-text"
            v-html="item.answer"
          ></div>
          <p class="text-center mb-0">
            <b-button
              v-show="state.toggle === item.id"
              variant="light"
              size="sm"
              @click="toggleAnswer(item.id)"
            >
              <span v-text="$t('txt.close')"></span>
            </b-button>
          </p>
        </b-list-group-item>
      </b-list-group>
      <p
        v-if="!state.mainFaq && state.toggle === 'all'"
        class="mb-0 mt-3"
      >
        <b-button
          block
          :variant="`outline-${bs} text-center`"
          @click="addMainFaq()"
        >
          <span v-html="$t('subfaq.more')"></span>
        </b-button>
      </p>
    </div>

    <div
      v-if="!/^Participer/.test(concern)"
      v-show="state.toggle === 'all'"
      class="no-answer"
    >
      <!-- No answer ? -->
      <p v-html="$t('subfaq.noanswer')"></p>
      <div v-if="/(help|support)/.test(section)">
        <div v-if="$te(`doc.${$t(category, '-k~lL@')}`)">
          <p
            v-html="$t('subfaq.help.docs', {
              service: $t(`color.${$t(category, '-k~lL@')}`)
            })"
          ></p>
          <b-col
            sm="6"
            class="mx-auto p-0"
          >
            <a
              :href="`${$t('link.docs')}/${$t(`doc.${$t(category, '-k~lL@')}`)[0]}`"
              class="btn btn-block border mb-4"
              onclick="return false;"
              @click="$root.$children[0].modal.docs = true;
                      $root.$children[0].modal.link =
                        `${$t('link.docs')}/${$t(`doc.${$t(category, '-k~lL@')}`)[0]}`"
            >
              <i
                :class="`${$t('icon.docs')} fa-lg fc-o7 fa-shape-circle
                fa-white fa-block-center`"
              ></i>
              <span v-html="$t('subfaq.menu.docs')"></span>
            </a>
          </b-col>
        </div>
      </div>
      <div v-html="$t('subfaq.help.next')"></div>
      <b-row>
        <b-col sm="6">
          <a
            :href="`${$t('link.contact')}/#${category}`"
            class="btn btn-block border mb-2"
            onclick="return false;"
            @click="showContact()"
          >
            <i
              :class="`fas fa-envelope fa-lg fc-${bs} fa-shape-circle
              fa-white fa-block-center`"
            ></i>
            <span v-html="`${$t('subfaq.contact')} ${$t('color.soft')}`"></span>
          </a>
        </b-col>
        <b-col sm="6">
          <a
            :href="`${$t('link.colibri')}${section === 'help' ? '/c/entraide/53' : ''}`"
            class="btn btn-block border mb-2"
          >
            <i
              :class="`fas fa-comment fa-lg fc-${bs} fa-shape-circle
              fa-white fa-block-center`"
            ></i>
            <span v-html="$t('subfaq.forum')"></span>
          </a>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    concern: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      mainFaq: [],
      categoryFaq: [],
      faq: [],
      search: '',
      categories: Object.keys(this.$t('faq.ranking')).sort(),
      category: '',
      service: this.concern.split(' - ')[2],
      state: {
        mainFaq: false,
        toggle: 'all',
        status: false,
      },
      id: '',
      status: [],
      bs: 'primary',
      html: {
        git: ` <i class="fab fa-git"></i>
          <span class="sr-only">git</span>`,
        source: ` <i class="fas fa-code-branch"></i>
          <span class="sr-only">source</span>`,
        link: `${this.$t('baseurl')}#issue-git`,
      },
    };
  },

  watch: {
    concern(newValue) {
      if (newValue) {
        this.id = '0';
        switch (this.concern.split(' - ')[0]) {
          case 'Soutenir':
            this.category = 'donations';
            this.bs = 'warning';
            break;
          case 'Invitation à un événement':
            this.category = 'misc';
            this.bs = 'warning';
            break;
          case 'Partenariat':
            this.category = 'partnership';
            this.bs = 'primary';
            break;
          case 'Framablog':
            this.category = 'partnership';
            this.bs = 'primary';
            break;
          case 'Framabook':
            this.category = 'partnership';
            this.bs = 'primary';
            break;
          case 'Participer':
            this.category = 'benevolat';
            this.bs = 'primary';
            break;
          case '---': // general
            this.category = 'misc';
            this.bs = 'primary';
            break;
          default:
            this.category = this.$t(this.concern, '-ktlL@');
            this.id = this.$te(`status.${this.$t(this.category, '-k~')}`)
              ? this.$t(`status.${this.$t(this.category, '-k~')}`)
              : '0';
            this.bs = 'warning';
            break;
        }
        this.faq = [];
        this.categoryFaq = [];
        this.$parent.$parent.$parent.activity = [new Date().getTime()];
        this.status = [];
        this.state.status = false;
        this.search = '';
        this.checkStatus();
        this.loadFaq();
      }
    },

    search(newValue) {
      if (newValue) {
        this.searchUpdate();
      }
    },

    status(newValue) {
      if (newValue) {
        this.faq = this.categoryFaq;

        if (this.faq.length === 0) {
          this.faq = this.mainFaq;
          this.state.mainFaq = true;
        }

        if (this.status.length > 0) {
          this.faq = this.status.concat(this.faq);
        }
      }
    },
  },

  methods: {
    getFramaname(key) {
      return this.$te(`color.${this.$t(key, '-k~lL@')}`)
        ? this.$t(`color.${this.$t(key, '-k~lL@')}`)
        : undefined;
    },

    loadFaq() {
      let q = this.$t('faq.ranking.misc');
      for (let i = 0; i < q.length; i += 1) {
        this.mainFaq[i] = {
          id: q[i],
          question: this.$t(`faq.misc.${q[i]}.q`),
          answer: this.$t(`faq.misc.${q[i]}.a`)
            .replace(/<a href="https:\/\/contact.framasoft.org([\S\s]*?)">([\S\s]*?)<\/a>/g, '$2'),
          variant: '',
        };
      }

      /* Site Faq */
      if (this.categories.includes(this.category)) {
        q = this.$t(`faq.ranking.${this.category}`);
        for (let i = 0; i < q.length; i += 1) {
          this.categoryFaq[i] = {
            id: q[i],
            question: this.$t(`faq.${this.category}.${q[i]}.q`),
            answer: this.$t(`faq.${this.category}.${q[i]}.a`)
              .replace(/<a href="https:\/\/contact.framasoft.org([\S\s]*?)">([\S\s]*?)<\/a>/g, '$2'),
            variant: '',
          };
        }
      }
      this.faq = this.categoryFaq;

      if (this.faq.length === 0) {
        this.faq = this.mainFaq;
        this.state.mainFaq = true;
      }

      if (this.status.length > 0) {
        this.faq = this.status.concat(this.faq);
      }
    },

    toggleAnswer(id) {
      if (this.state.toggle === 'all') {
        this.$parent.$parent.$parent.activity.push(id);
      }
      this.state.toggle = this.state.toggle === 'all' ? id : 'all';
      window.setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },

    showContact() {
      this.$parent.$parent.$parent.state = {
        section: this.section,
        concern: this.concern,
        active: 4,
        disabled: /(support|general)/.test(this.section) ? [2] : [],
      };
      document.getElementById('sections').scrollIntoView({ behavior: 'smooth' });
    },

    addMainFaq() {
      this.faq = this.faq.concat(this.mainFaq);
      this.state.mainFaq = true;
    },

    searchUpdate() {
      const words = this.$t(this.search.replace(/\+ ?/, '_'), '-kLl@').split('_');
      const reg = new RegExp(`(${words.join('|')})`, 'g');

      if (!this.state.mainFaq) { /* search = more results */
        this.addMainFaq();
      }
      this.state.toggle = 'all';

      for (let i = 1; i < this.faq.length; i += 1) {
        const content = this.$t(`${this.faq[i].question} ${this.faq[i].answer}`, '-kLl@');
        if (reg.test(content)) {
          this.faq[i].variant = `${this.faq[i].variant.replace(/search/g, '')} search`;
        } else {
          this.faq[i].variant = this.faq[i].variant.replace(/search/g, '');
        }
      }
    },

    checkStatus() {
      /* Status */
      if (this.id !== '0' && !this.state.status) {
        fetch(`https://status.framasoft.org/api/v1/components?id=${this.id}`)
          .then(res => res.json())
          .then((data) => {
            if (data.data[0].status > 1) { // There is a incident
              const variant = data.data[0].status < 4 ? 'warning' : 'danger';
              this.loadLastIncident(this.id, variant);
            }
            this.state.status = true;
          })
          .catch((err) => { console.error(err); }); // eslint-disable-line
      }
      this.loadScheduledIncidents();
    },

    loadLastIncident(id, variant) {
      fetch(`https://status.framasoft.org/api/v1/incidents?component_id=${id}&sort=id&order=desc`)
        .then(res => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            this.status.unshift({
              id: 'incident_0',
              question: data.data[0].name,
              answer: `<div>${this.$t(data.data[0].message.replace('\r\n', '<br>'), '-k')}</div>`,
              variant,
            });
          }
        })
        .catch((err) => { console.error(err); }); // eslint-disable-line
    },

    loadScheduledIncidents() {
      fetch('https://status.framasoft.org/api/v1/incidents?component_id=0&sort=id&order=desc')
        .then(res => res.json())
        .then((data) => {
          for (let i = 0; i < data.data.length; i += 1) {
            if (data.data[i].scheduled_at) {
              const day = data.data[i].scheduled_at.replace(/-/g, '/').substr(0, 10);
              const nextDay = new Date(new Date(day).getTime() + (2 * 24 * 60 * 60 * 1000))
                .toISOString()
                .split('T')[0]
                .replace(/-/g, '/');
              if (this.is.before(nextDay) && this.getFramaname(this.category) !== undefined) {
                const reg = new RegExp(`(${this.$t(this.getFramaname(this.category), '-tLl')})`, 'g');
                const content = this.$t(`${data.data[i].message} ${data.data[i].name}`, '-kLl');
                if (reg.test(content)) {
                  this.status.unshift({
                    id: `maintenance_${i + 1}`,
                    question: data.data[i].name,
                    answer: `<div>${this.$t(data.data[i].message.replace('\r\n', '<br>'), '-k')}</div>`,
                    variant: 'info',
                  });
                }
              }
            }
          }
        })
        .catch((err) => { console.error(err); }); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
#subfaq {
  a.toggle {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    margin-top: -1.35rem;
    z-index: 1;
    outline: 0;

    &::after {
      content: "[…]";
      position: absolute;
      bottom:5px;
      right: 5px;
      color: var(--f-g6);
      font-weight: bold;
      font-size: 0.75rem;
    }
  }

  .list-group-item-text {
    font-size: 0.875rem;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    filter: grayscale(80%);
  }

  .close {
    margin: -5px -10px 5px 10px;
  }

  .list-group-item {
    padding: 1.5rem;
    padding-right: 1.65rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 0 !important;

    h3 {
      font-size: 0.95rem;
      font-weight: bold;
      border-bottom: 1px dashed #ddd;
      margin: 0 0 10px;
      padding: 0 0 12px;
    }

    h4 {
      font-size: 13px;
    }

    b, strong {
      font-weight: normal;
    }

    ul {
      padding-left: 20px;
    }

    &:hover {
      box-shadow: 0px 2px 6px #ddd;
    }

    &.active {
      background: #fff;

      h3 {
        font-size: 22px;
        color: var(--f-d9);
        font-weight: normal;
      }

      h4 {
        font-size: 16px;
      }

      b, strong {
        font-weight: bold;
      }

      a.toggle {
        position: relative;
        top: -30px;

        &:after {
          display: none;
        }
      }

      .list-group-item-text {
        color: var(--f-d9);
        max-height: initial;
        filter: grayscale(0%);
      }

      &:after {
        display: none;
      }
    }
  }

  p.text-right,
  .list-group-item:not(.active) {
    display: none;
  }

  .all .list-group-item:not(.active) {
    display: block;
  }

  .all.filter .list-group-item {
    display: none;
  }

  .all.nofilter .list-group-item,
  .all.filter .list-group-item.search {
    display: block;
  }

  .no-answer {
    margin-top: 30px;
  }
}
</style>
