<template>
  <b-list-group-item class="bg-light position-sticky sticky-top">
    <b-input-group>
      <b-input-group-append>
        <label
          class="sr-only"
          for="faq-search"
          v-html="$t('txt.search')"
        ></label>
        <b-button
          aria-hidden="true"
          variant="text-muted"
          @click="$parent.search = ''; wSearch = ''"
        >
          <i
            v-show="wSearch === ''"
            class="fas fa-magnifying-glass fc-g6"
          ></i>
          <i
            v-show="wSearch !== '' && state.typing"
            class="fas fa-keyboard fc-g6"
          ></i>
          <i
            v-show="wSearch !== '' && !state.typing"
            class="fas fa-delete-left fc-g6"
          ></i>
        </b-button>
      </b-input-group-append>
      <b-form-input
        id="faq-search"
        v-model="wSearch"
        :placeholder="$t('txt.search')"
        type="text"
        size="40"
        class="rounded-0"
      />
    </b-input-group>
  </b-list-group-item>
</template>

<script>
export default {
  props: {
    faq: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      wFaq: this.faq,
      wSearch: this.search,
      state: {
        typing: false,
      },
    };
  },

  watch: {
    wSearch(newValue) {
      if (newValue) {
        if (!this.state.typing) {
          this.state.typing = true;
          setTimeout(() => {
            this.state.typing = false;
            this.searchUpdate();
          }, 500);
        }
      }
    },
  },

  methods: {
    searchUpdate() {
      const words = this.$t(this.wSearch.replace(/\+ ?/, '_'), '-kLl@').split('_');
      const reg = new RegExp(`(${words.join('|')})`, 'g');

      Object.keys(this.wFaq)
        .forEach((key) => {
          let count = 0;
          Object.keys(this.wFaq[key]).filter(i => !/variant/.test(i))
            .forEach((id) => {
              const content = this.$t(`${this.wFaq[key][id].q} ${this.wFaq[key][id].a}`, '-kLl@');
              if (reg.test(content)) {
                this.wFaq[key][id].variant = `${this.wFaq[key][id].variant.replace(/search/g, '')} search`;
                count += 1;
              } else {
                this.wFaq[key][id].variant = this.wFaq[key][id].variant.replace(/search/g, '');
              }
            });
          this.wFaq[key].variant = count > 0
            ? `${this.wFaq[key].variant.replace(/search/g, '')} search`
            : this.wFaq[key].variant.replace(/search/g, '');
        });

      this.$parent.faq = this.wFaq;
      this.$parent.search = this.wSearch;
    },
  },
};
</script>
