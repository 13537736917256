<template>
  <b-modal
    id="c-modal-docs"
    v-model="state.docs"
    size="lg"
    :static="true"
    :lazy="true"
    :title="$t('feedback.menu.docs')"
    :title-sr-only="true"
    :hide-header="true"
    :cancel-title-html="$t('txt.close')"
    cancel-variant="light"
    button-size="sm"
    @hidden="close()"
  >
    <div class="clearfix">
      <div class="embed-responsive embed-responsive-1by1">
        <iframe
          v-if="state.docs === true"
          class="embed-responsive-item"
          :src="link"
        ></iframe>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      state: {
        docs: false,
      },
    };
  },

  watch: {
    open(newValue) {
      if (newValue) {
        this.state.docs = true;
      }
    },
  },

  methods: {
    close() {
      this.state.docs = false;
      this.$root.$children[0].modal.docs = false;
    },
  },
};
</script>

<style lang="scss">
#c-modal-docs {
  .modal-content {
    border-radius: 0;
    border: none;
    border-top: 8px solid var(--f-o6);
    /*height: 94vh;*/
    background: #fff; /* ~team */
  }

  .modal-body {
    padding: 0;
    max-height: none;
    background: transparent; /* ~team */
  }

  .modal-footer {
    border: none;
    border-radius: 0;
    margin: 0; /* ~mindmap */
    background: transparent; /* ~team */
    justify-content: center;
    background-color: var(--f-o6);

    .btn {
      text-transform: uppercase;
    }

    .btn-primary {
      display: none;
    }
  }
}
</style>
