<template>
  <div :class="filter">
    <b-list-group
      v-for="key in Object.keys(wFaq).filter(key => !/(variant)/.test(key))"
      :id="getFramaname(key) !== undefined
        ? $t(getFramaname(key), '-tlL').replace(/[^a-z]/g, '')
        : key"
      :key="key"
      :class="wFaq[key].variant"
    >
      <b-btn
        :href="`${$t('config.wiki.base')}${
          $t('lang') === 'fr' ? 'fr' : 'en'}/faq/${key}/Article title`"
        size="sm"
        variant="outline-secondary float-right mt-4 border-0 btn-wiki-edit"
      >
        <i class="fas fa-plus"></i>
        <span v-html="$t('faq.add')"></span>
      </b-btn>
      <h2 class="mt-3">
        <i :class="`${getSectionIcon(key)} fc-g6`"></i>
        <span v-html="getFramaname(key) ? getFramaname(key) : $t(`faq.${key}.title`)"></span>
      </h2>
      <b-list-group-item
        v-for="id in Object.keys(wFaq[key]).filter(id => !/variant/.test(id))"
        :key="id"
        :class="`${toggle === id ? 'active' : ''}${wFaq[key][id].variant}`"
      >
        <b-btn
          :href="`${$t('config.wiki.base')}${
            encodeURIComponent($t(`faq.${key}.${id}.e`))}/edit`"
          size="sm"
          :title="$t('faq.edit')"
          variant="outline-secondary badge float-right mt-n3 mr-n2 btn-wiki-edit"
        >
          <i class="fas fa-pencil"></i>
          <span
            class="sr-only"
            v-html="$t('faq.edit')"
          ></span>
        </b-btn>
        <a
          aria-hidden="true"
          :href="`#${id}`"
          class="toggle"
          onclick="return false"
          @click="toggle = id.replace(toggle, '')"
        ></a>
        <a
          :id="id"
          :href="`#${id}`"
          @click="toggle = id.replace(toggle, '')"
        >
          <h3
            class="list-group-item-heading"
          >
            <i
              v-show="toggle === id"
              class="fas fa-xmark m-n3 float-right fc-g8"
            ></i>
            <span v-html="$t(`faq.${key}.${id}.q`)"></span>
          </h3>
        </a>
        <div class="list-group-item-text">
          <div v-html="$t(`faq.${key}.${id}.a`)"></div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  props: {
    faq: {
      type: String, // don't work with Object :-/
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      toggle: window.location.hash.replace(/^#/, ''),
      filter: 'nofilter',
      wFaq: JSON.parse(this.faq),
    };
  },

  watch: {
    faq(newValue) {
      if (newValue) {
        this.wFaq = JSON.parse(this.faq);
      }
    },
    search(newValue) {
      this.filter = newValue && this.search !== '' ? 'filter' : 'nofilter';
    },
  },

  methods: {
    getFramaname(key) {
      return this.$te(`color.${this.$t(key, '-k~lL@')}`)
        ? this.$t(`color.${this.$t(key, '-k~lL@')}`)
        : undefined;
    },

    getSectionIcon(key) {
      let icon = 'fas fa-circle-question';
      if (this.$te(`icon.${this.$t(key, '-k~L@')}`)) {
        icon = this.$t(`icon.${this.$t(key, '-k~L@')}`);
      }
      if (this.$te(`faq.icon.${key}`)) {
        icon = this.$t(`faq.icon.${key}`);
      }
      return icon;
    },
  },
};
</script>
